.App, html, body, #root {
  text-align: center;
  background-color: #393d3f;
  height: 100%;
  width: 100vw;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
    display: none;
}

.circular--portrait {
  position: relative;
  width: 80vw;
  max-width: 400px;
  /* height: 400px; */
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-position: center center;
}

.circular--portrait img {
  position: absolute;
  left: -100%;
  right: -180%;
  top: -110%;
  bottom: -100%;
  margin: auto;
  height: 120%;
}


.particles-js {
  margin: 0;
  position : fixed;
  width:99vw;
  height:100vh;
}

.h100 {
  margin:0 0 0 0;
  padding:10px;
  height:100vh;
}

.sidenav {
  position: fixed;
  /*min-height: 100vh;*/
  padding-left: 20px;
}


.nav-content {
  max-width: 300px;
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-items {
  text-align: start;
  list-style: none;
  line-height: 2;
}

.nav-item {
  margin-left: 0;
  padding-left: 1rem;
  color: #ffffff;
  transition: margin-left 700ms;
}

.activeNav {
  margin-left: 1rem;
  padding-right: 1rem;
  transition: margin-left 700ms;
}

.nav-item:hover {
  color: black;
  cursor: auto;
  background: #bdc4cd;
  margin-left: 1rem;
}

.pt20 {
  padding-top: 20px;
}

.test {
    display: flex;
    justify-content: center;
    text-align: -webkit-center;

}


/* CSS HEX
--dodger-blue: #1890ffff;   PRIMARY
--onyx: #393d3fff;          BACKGROUND
--white: #fdfdffff;         WHITE
--silver: #c6c5b9ff;        SILVER
--citrine: #e0ca3cff;       YELLOW
*/